.css-yafthl-MuiSlider-markLabel {
    top: -10px !important;
}

.css-1eoe787-MuiSlider-markLabel {
    top: -10px !important;
}

.css-6cwnna-MuiSlider-mark {
    padding-bottom: 99px !important;
}

.css-eg0mwd-MuiSlider-thumb {
    width: 6px !important;
    height: 77px !important;
    margin-top: 11px !important;
    background-color: red !important;
    border-radius: 0% !important;
}

.css-1tfve6q-MuiSlider-mark {
    background-color: red !important;
}

.css-1gv0vcd-MuiSlider-track {
    border: 1px solid red !important;
    background-color: red !important
}