.mainContainer {
  width: 100%;
  background: #ff8232;
  margin-top: -27px;
  margin-bottom: 50px;
  height: 70px;
  position: relative;
  top: 30px;
  border: 5px solid black;
  border-left: 0;
  border-right: 0;
  overflow: hidden;
}

@media (max-width: 900px) {
  .mainContainer {
    left: -300px;
  }
}

.redLine {
  width: 5px;
  background: red;
  height: 100%;
  position: absolute;
  top: 0;
  left: 25%;
}
